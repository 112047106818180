<template>
  <div>
    <table-filter
      v-if="modalIsActive"
      :range="true"
      @close="toggleFilter"
      @save="setFilter"
    />
    <div class="columns">
      <div class="column">
        <button
          class="button is-info is-size-7-mobile"
          @click="$modal.open('account/AccountAdminAddUser')"
        >
          New user
        </button>
      </div>
      <div class="column is-narrow">
        <div class="field is-grouped">
          <div class="control">
            <table-search
              @change="setSearch"
              placeholder="Search by name or email"
            />
          </div>
          <div class="control">
            <date-picker-button
              @toggleFilter="toggleFilter"
              @clearFilter="clearFilter"
              :to="query.to"
              :from="query.from"
            />
          </div>
          <div class="control">
            <DownloadCSVButton
              :columns="csvColumns"
              :get-entries="fetchCsvEntries"
              postfix="users"
            />
          </div>
        </div>
      </div>
    </div>
    <table
      class="table is-fullwidth is-size-7-mobile is-striped is-transparent"
    >
      <table-header
        v-bind="{ columns, sort }"
        @change="setSort"
        v-if="!isEmpty"
      />
      <tbody v-if="!rows">
        <tr>
          <td v-for="(column, index) in columns" :key="index">
            <content-placeholder />
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="rows.length > 0">
        <tr v-for="(row, index) in rows" :key="index">
          <td>{{ row.name }}</td>
          <td>{{ row.email }}</td>
          <td v-if="isCompanionUser">
            <span>{{ row.companion || '-' }}</span>
          </td>
          <td v-if="hasAutoCheck">
            <span>{{ row.autocheck || '-' }}</span>
          </td>
          <td v-if="hasAutoCheckValuation">
            <span>{{ row.autocheckValuation || '-' }}</span>
          </td>
          <td>
            <span>{{ row.vpp || '-' }}</span>
          </td>
          <td>{{ row.createdAt ? getDate(row.createdAt) : '-' }}</td>
          <td>
            <table-action-buttons
              :branch-id="branchId"
              :user-id="row.id"
              :stockview-email="row.stockviewEmail"
              :has-stockview-access="hasStockview"
              @remove="remove(row.id)"
              @password="resetPassword(row.id)"
              @toggleStockviewEmail="toggleStockviewEmail"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <table-empty v-if="isEmpty" />
    <pagination v-bind="{ page, pages }" :fn="'fetch'" @change="setPage" />
  </div>
</template>

<script>
import * as account from 'modules/account/services'
import { mapGetters } from 'vuex'
import pagination from 'core/modules/pagination/mixins/pagination.mixin'
import tableHeader from 'core/modules/table-header/mixins/tableHeader.mixin'
import tableFilter from 'core/modules/table-filter/mixins/tableFilter.mixin'
import tableSearch from 'core/modules/table-search/mixins/tableSearch.mixin'
import activityTable from 'core/mixins/activityTable'
import csv from '@/modules/csv/mixins/csv.mixin'
import { formatDate } from '@/utils/date.utils.ts'
export default {
  name: 'AccountAdminUserTable',
  components: {
    tableHeader: () =>
      import('core/modules/table-header/components/TableHeader'),
    contentPlaceholder: () => import('core/components/ContentPlaceholder'),
    tableFilter: () =>
      import('core/modules/table-filter/components/TableFilter'),
    tableEmpty: () => import('core/components/TableEmpty'),
    tableActionButtons: () => import('core/components/TableActionButtons'),
    datePickerButton: () =>
      import('core/modules/table-filter/components/DatePickerButton'),
    DownloadCSVButton: () =>
      import('@/modules/csv/components/DownloadCSVButton')
  },
  data: () => ({
    response: null
  }),
  created() {
    this.fetch()
  },
  mixins: [
    csv,
    activityTable,
    tableHeader({ fetch: 'fetch' }),
    tableFilter({ fetch: 'fetch' }),
    tableSearch({ fetch: 'fetch' }),
    pagination({
      fn: {
        data: 'response',
        fetch: 'fetch'
      }
    })
  ],
  computed: {
    ...mapGetters('auth', [
      'branch',
      'hasAutoCheck',
      'hasAutoCheckValuation',
      'isCompanionUser',
      'hasStockview'
    ]),
    branchId() {
      return this.branch.id
    },
    columns() {
      let columns = [
        { label: 'Name', type: 'name', query: true, path: 'name' },
        { label: 'Email', type: 'email', query: true, path: 'email' },
        {
          label: 'Companion',
          type: 'companion',
          query: true,
          path: 'companion'
        },
        {
          label: 'AutoCheck',
          type: 'autocheck',
          query: true,
          path: 'autocheck'
        },
        {
          label: 'Valuation',
          type: 'autocheckValuation',
          query: true,
          path: 'autocheckValuation'
        },
        { label: 'VPP', type: 'vpp', query: true, path: 'vpp' },
        {
          label: 'User since',
          type: 'created_at',
          query: true,
          path: 'createdAt'
        },
        { label: 'Actions' }
      ]
      if (!this.hasAutoCheck)
        columns = columns.filter((col) => col.type !== 'autocheck')
      if (!this.hasAutoCheckValuation)
        columns = columns.filter((col) => col.type !== 'autocheckValuation')
      if (!this.isCompanionUser)
        columns = columns.filter((col) => col.type !== 'companion')
      return columns
    },
    rows() {
      return this.response?.results
    }
  },
  mounted() {
    this.$root.$on('fetch', () => this.fetch())
  },
  methods: {
    async fetch(req) {
      const { query } = this

      try {
        this.$wait.start('response')
        const { users } = await account.getBranchUsers({ query, req })
        this.response = users
      } catch (err) {
        if (err?.response?.status !== 401) {
          this.$notify('Usage unavailable')
          throw err
        }
      } finally {
        this.$wait.end('response')
      }
    },
    async fetchCsvEntries(req) {
      const { query } = this

      const { users } = await account.getBranchUsers({
        req,
        query: { ...query, limit: 1000000, page: 1 }
      })

      return users.results
    },
    getDate: (value) => formatDate(value, 'yyyy/MM/dd'),
    async remove(userId) {
      await account.removeBranchUser(userId)
      this.fetch()
    },
    async resetPassword(userId) {
      await account.resetUserPassword(userId)
    },
    async toggleStockviewEmail(userId, stockviewEmail) {
      await account.toggleStockviewEmail(userId, stockviewEmail)
      this.fetch()
    }
  }
}
</script>
