export default {
  computed: {
    csvColumns() {
      return this.columns
        .map(column => {
          return {
            label: column.label,
            path: column.path,
            format: column.path === 'createdAt' ? 'date' : undefined
          }
        })
        .filter(column => column.path)
    }
  }
}
